<template>
  <div
    :class="[bem({
      parent:true
    }), autoHeight ? 'vh-100' : null]"
  >
    <div class="text-center">
      <img src="../../assets/images/update-info.png" alt="">
    </div>
    <div class="text-center">
      <div :class="bem('title')">
        网站正在升级中...
      </div>
      <div :class="bem('text')">
        为了进一步优化客户体验，易理货网站暂停访问， 预计{{ updateEndTime|date('yyyy-MM-dd hh:mm') }}可正常访问，给您带来的不便 敬请谅解，感谢您的理解和支持！
      </div>
    </div>
  </div>
</template>
<script>
import bemMixin from '../../mixins/class-name'
import { storage } from '@jsh/helper/utils'
export default {
  mixins: [bemMixin('update')],
  data () {
    return {
      autoHeight: true,
      updateEndTime: null
    }
  },
  mounted () {
    this.autoHeight = window.innerHeight > 700
  },
  created () {
    if (this.$route.query.upt) {
      this.updateEndTime = this.$route.query.upt * 1
    } else {
      this.updateEndTime = storage.getItem('updateEndTime') * 1
    }

    if (Date.now() > this.updateEndTime) {
      // 升级结束，跳转首页
      this.$router.push('/')
    }
  }
}
</script>
<style lang="scss" scoped>
.update {
  background-image: url("../../assets/images/update-bg.png");
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: auto;
  background-color: #EBF7FF;
  min-height: 700px;

  &_title {
    font-size: 20px;
    font-weight: bold;
    color: #46789C;
    line-height: 24px;
    margin-bottom: 12px;
    margin-top: 30px;
  }

  &_text {
    font-size: 18px;
    font-weight: 400;
    color: #46789C;
    line-height: 24px;
  }
}
</style>
